import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './App.css';

import { useBrand } from './hooks/brand';
import Avatar from './components/avatar';

const App = (props) => {
  const { loginWithRedirect, user, isAuthenticated } = useAuth0();
  const { brandSlogan, brandLogo, brandBrief, favicon, redirectUrl } = useBrand(props.brand);

  document.title = brandSlogan;
  document.getElementById("favicon").href = favicon;

  function accessApp() {
    if (isAuthenticated) {
      window.location.replace(redirectUrl);
    } else {
      loginWithRedirect({
        async onRedirect(url) {          
          url += `&brand_logo=${encodeURIComponent(brandLogo)}`;
          url += `&brand_name=${brandSlogan}`;
          url += `&brand_favicon=${encodeURIComponent(favicon)}`;
          url += `&brand_message=${brandBrief}`;
          window.location.replace(url);
        }
      })
    }
  }

  return (
    <div className="container">
      <header className="header">
        <img src={brandLogo} alt="Logo" className="logo" /> 
        {
          isAuthenticated
          ? <Avatar
              brand={props.brand}
              email={user.email} 
              size={30} />
          : <button className="login-button" onClick={() => accessApp()}>Login</button>
        }        
      </header>
      <main className="main-content">
        <h1 className="main-title">Integrated Development <br/>Environment</h1>
        <p className="subtitle">Complexity made easy. Ready to use with no installation. <br/>Intuitive workspace and built in advanced security.</p>
        <p className="description">Speed up your development with <br/>our integrated real-time collaboration feature.</p>
        <button className="access-button" onClick={() => accessApp()}>
          Access Application <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </main>
    </div>
  );
};

export default App;
