import React from 'react';
import './error.css';
import { apiErrorCodes, ZohoStatusCodes } from './error-codes';

const ErrorPage = ({ code }) => {
  let matchedError = apiErrorCodes.find(i => i.code === code);
  if (!matchedError) {
    matchedError = apiErrorCodes.find(i => i.code === ZohoStatusCodes.UNKNOWN_ERROR);
  }
  return (
    <div className="error-page-container">      
      <h1>{matchedError.status}</h1>
      <h2>We're sorry</h2>
      <p>{matchedError.message}</p>
    </div>
  );
};

export default ErrorPage;
