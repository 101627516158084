const HTTP_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

const ZohoStatusCodes = {
  DEVELOPER_API_LIMIT_REACHED: 4000,
  MAXIMUM_RECORD_FETCH_EXCEEDED: 3970,
  MAXIMUM_RECORD_PROCESS_EXCEEDED: 3960,
  MAXIMUM_RECORD_ADD_EXCEEDED: 3950,
  NO_REPORTS_AVAILABLE: 3930,
  NO_PAGES_AVAILABLE: 3920,
  NO_FORMS_AVAILABLE: 3910,
  NO_PERMISSION_TO_TRANSFER: 3830,
  NO_SUBFORM_FOUND_WITH_NAME: 3780,
  FIELD_TYPE_IS_NOT_FILE: 3750,
  NO_FILE_FOUND_WITH_ID: 3730,
  NO_FIELD_FOUND_WITH_NAME: 3710,
  NO_FILE_FOUND_IN_REQUEST: 3660,
  INPUT_FILE_LIMIT_EXCEEDED: 3630,
  NO_RECORD_FOUND_WITH_ID: 3190,
  DATA_TYPE_MISMATCH: 3120,
  NO_RECORDS_FOUND_WITH_CRITERIA: 3100,
  CRITERIA_IS_INVALID: 3090,
  DATA_VALIDATION_FAILURE: 3070,
  RECORD_LIMIT_EXCEEDED: 3060,
  FILE_UPLOAD_NOT_ALLOWED: 3050,
  REQUEST_BODY_MALFORMED: 3030,
  REQUEST_BODY_INVALID: 3020,
  FEATURE_NOT_AVAILABLE: 3002,
  NO_MORE_ENTRIES_ALLOWED: 2965,
  AUTHORIZATION_ERROR: 2945,
  NO_PERMISSON: 2933,
  UNKNOWN_ERROR: 2930,
  PERMISSION_DENIED_TO_ADD_RECORDS: 2899,
  PERMISSION_DENIED_TO_VIEW_RECORDS: 2898,
  PERMISSION_DENIED_TO_UPDATE_RECORDS: 2897,
  PERMISSION_DENIED_TO_DELETE_RECORDS: 2896,
  NO_REPORT_FOUND_WITH_NAME: 2894,
  NO_FORM_FOUND_WITH_NAME: 2893,
  NO_APPLICATION_FOUND_WITH_NAME: 2892,
  NO_PERMISSION_TO_ACCESS_API: 1130,
  NO_PERMISSION_TO_ACCESS_COMPONENT: 1100,
  NO_COMPONENT_FOUND_WITH_NAME: 1090,
  API_CALLS_CANNOT_BE_MADE_WITH_DISABLED_APPLICATIONS: 1080,
  NO_PERMISSION_TO_ACCESS_APPLICATION: 1060,
  NO_ACCOUNT_OWNER_WITH_NAME: 1040,
  ACCESS_TOKEN_INVALID_OR_EXPIRED: 1030,
  INVALID_METHOD: 1020,
  INVALID_API_URL_FORMAT: 1000,
  SUCCESS: 3000,
  API_CALL_LIMIT_EXCEEDED_PER_MINUTE: 2955,
  API_LOOP_LIMIT_TO_SAME_COMPONENT_EXCEEDED: 9030,
  API_LOOP_LIMIT_TO_OTHER_COMPONENT_EXCEEDED: 9040
}

/**
 * This converts ZOHO status codes.
 * Every code has its own message above it.
 */
const apiErrorCodes = [
  /* Account's Developer API limit has been reached. Please upgrade to execute more REST API calls. */
  { code: ZohoStatusCodes.DEVELOPER_API_LIMIT_REACHED, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 4000)' },
  /* A maximum of 200 records can be fetched per request. */
  { code: ZohoStatusCodes.MAXIMUM_RECORD_FETCH_EXCEEDED, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3970)' },
  /* A maximum of 200 records can be processed per request. Include the "process_until_limit" parameter to process the first 200 records. */
  { code: ZohoStatusCodes.MAXIMUM_RECORD_PROCESS_EXCEEDED, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3960)' },
  /* 	A maximum of 200 records can be added per request. */
  { code: ZohoStatusCodes.MAXIMUM_RECORD_ADD_EXCEEDED, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3950)' },
  /* No reports available. */
  { code: ZohoStatusCodes.NO_REPORTS_AVAILABLE, status: HTTP_STATUS_CODES.OK, message: 'No reports available.' },
  /* No pages available. */
  { code: ZohoStatusCodes.NO_PAGES_AVAILABLE, status: HTTP_STATUS_CODES.OK, message: 'No pages available.' },
  /* No forms available. */
  { code: ZohoStatusCodes.NO_FORMS_AVAILABLE, status: HTTP_STATUS_CODES.OK, message: 'No forms available.' },
  /* You do not have permission to transfer files to/from the field "<FIELD_NAME>". Please check with the application admin. */
  { code: ZohoStatusCodes.NO_PERMISSION_TO_TRANSFER, status: HTTP_STATUS_CODES.UNAUTHORIZED, message: 'You do not have permission. Please contact support for assistance.' },
  /* No subform named "<LINK_NAME"> found. Please check and try again. */
  { code: ZohoStatusCodes.NO_SUBFORM_FOUND_WITH_NAME, status: HTTP_STATUS_CODES.UNAUTHORIZED, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3780)' },
  /* "<LINK_NAME>" is not a file type field. Please check and try again. */
  { code: ZohoStatusCodes.FIELD_TYPE_IS_NOT_FILE, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3750)' },
  /* No file found in the record with ID "<RECORD_ID>".*/
  { code: ZohoStatusCodes.NO_FILE_FOUND_WITH_ID, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3730)' },
  /* No field named "<LINK_NAME>" found. Please check and try again. */
  { code: ZohoStatusCodes.NO_FIELD_FOUND_WITH_NAME, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3710)' },
  /* No file found in the request. Please include a file and try again. */
  { code: ZohoStatusCodes.NO_FILE_FOUND_IN_REQUEST, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3660)' },
  /* Input file exceeds the 50 MB limit. Please upload a smaller file. */
  { code: ZohoStatusCodes.INPUT_FILE_LIMIT_EXCEEDED, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'Input file exceeds the 50 MB limit. Please upload a smaller file.' },
  /* No record with ID "<RECORD_ID>" found. */
  { code: ZohoStatusCodes.NO_RECORD_FOUND_WITH_ID, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3190)' },
  /*
  For the lookup field:
  Data type mismatch. Value for the "<FIELD_NAME>" field must be of type INTEGER.
  For the drop down field:
  Data type mismatch. Value for the "<FIELD_NAME>" field must be of type STRING.
*/
  { code: ZohoStatusCodes.DATA_TYPE_MISMATCH, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3120)' },
  /* No records found for the given criteria. */
  { code: ZohoStatusCodes.NO_RECORDS_FOUND_WITH_CRITERIA, status: HTTP_STATUS_CODES.OK, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3100)' },
  /*
  When no criteria is passed:
  Criteria is required to perform this action.
  When an invalid criteria is passed:
  Invalid criteria specified.
  When a field's link name, that used in the criteria, is incorrect:
  Criteria refers to an invalid field named "<FIELD_NAME>".
*/
  { code: ZohoStatusCodes.CRITERIA_IS_INVALID, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3090)' },
  /* Data validation failure: "[ARRAY]" */
  { code: ZohoStatusCodes.DATA_VALIDATION_FAILURE, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3070)' },
  /* Account's record limit has been reached. Please upgrade to add more data. */
  { code: ZohoStatusCodes.RECORD_LIMIT_EXCEEDED, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3060)' },
  /* 
  Passing values for Formula, File Upload and Image type fields is not allowed.
  Use the Upload File API to update the File Upload and Image fields. 
*/
  { code: ZohoStatusCodes.FILE_UPLOAD_NOT_ALLOWED, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3050)' },
  /* API request's body is not properly formed. */
  { code: ZohoStatusCodes.REQUEST_BODY_MALFORMED, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3030)' },
  /* API request's body is either missing or incomplete. */
  { code: ZohoStatusCodes.REQUEST_BODY_INVALID, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3020)' },
  /*
  The "<FEATURE_NAME>" feature is currently not available for this Zoho Creator account. 
  Please contact support@zohocreator.com to learn more.
*/
  { code: ZohoStatusCodes.FEATURE_NOT_AVAILABLE, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 3002)' },
  /* No more entries allowed as per the form validation. */
  { code: ZohoStatusCodes.NO_MORE_ENTRIES_ALLOWED, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 2965)' },
  /* ERROR */
  { code: ZohoStatusCodes.AUTHORIZATION_ERROR, status: HTTP_STATUS_CODES.UNAUTHORIZED, message: "We couldn't verify your identity. Please check your login details and try again." },
  /* You do not have permission to access this application. */
  { code: ZohoStatusCodes.NO_PERMISSON, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'You do not have permission. Please contact support for assistance.' },
  /* Error Occurred. We are sorry for the inconvenience. */
  { code: ZohoStatusCodes.UNKNOWN_ERROR, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 2930)' },
  /* Permission denied to add record(s). */
  { code: ZohoStatusCodes.PERMISSION_DENIED_TO_ADD_RECORDS, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'You do not have permission. Please contact support for assistance.' },
  /* 	Permission denied to view record(s). */
  { code: ZohoStatusCodes.PERMISSION_DENIED_TO_VIEW_RECORDS, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'You do not have permission. Please contact support for assistance.' },
  /* Permission denied to update record(s). */
  { code: ZohoStatusCodes.PERMISSION_DENIED_TO_UPDATE_RECORDS, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'You do not have permission. Please contact support for assistance.' },
  /* Permission denied to delete record(s). */
  { code: ZohoStatusCodes.PERMISSION_DENIED_TO_DELETE_RECORDS, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'You do not have permission. Please contact support for assistance.' },
  /* No report named "<REPORT_NAME>" found. Please check and try again. */
  { code: ZohoStatusCodes.NO_REPORT_FOUND_WITH_NAME, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 2894)' },
  /* No form named "<FORM_NAME>" found. Please check and try again. */
  { code: ZohoStatusCodes.NO_FORM_FOUND_WITH_NAME, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 2893)' },
  /* No application named "<APPLICATION_NAME>" found. Please check and try again. */
  { code: ZohoStatusCodes.NO_APPLICATION_FOUND_WITH_NAME, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 2892)' },
  /* You do not have permission to access this application's APIs. Please check with the application admin. */
  { code: ZohoStatusCodes.NO_PERMISSION_TO_ACCESS_API, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'You do not have permission. Please contact support for assistance.' },
  /* You do not have permission to access this component. Please check with the application admin. */
  { code: ZohoStatusCodes.NO_PERMISSION_TO_ACCESS_COMPONENT, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'You do not have permission. Please contact support for assistance.' },
  /* No component named "<COMPONENT_NAME>" found in this application. Please check and try again. */
  { code: ZohoStatusCodes.NO_COMPONENT_FOUND_WITH_NAME, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 1090)' },
  /* API calls cannot be made to disabled applications. Please check with the application admin. */
  { code: ZohoStatusCodes.API_CALLS_CANNOT_BE_MADE_WITH_DISABLED_APPLICATIONS, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 1080)' },
  /* You do not have permission to access this application. Please check with the application admin. */
  { code: ZohoStatusCodes.NO_PERMISSION_TO_ACCESS_APPLICATION, status: HTTP_STATUS_CODES.FORBIDDEN, message: 'You do not have permission. Please contact support for assistance.' },
  /* No account owner named "<OWNER_NAME>" found. */
  { code: ZohoStatusCodes.NO_ACCOUNT_OWNER_WITH_NAME, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 1040)' },
  /* Authorization Failure. The access token is either invalid or has expired. Please check your Zoho Account for more information. */
  { code: ZohoStatusCodes.ACCESS_TOKEN_INVALID_OR_EXPIRED, status: HTTP_STATUS_CODES.UNAUTHORIZED, message: 'Your authentication token has expired. Please try signing again.' },
  /* "<METHOD>" is not a valid method for this request. */
  { code: ZohoStatusCodes.INVALID_METHOD, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 1020)' },
  /* Invalid API URL format. */
  { code: ZohoStatusCodes.INVALID_API_URL_FORMAT, status: HTTP_STATUS_CODES.UNAUTHORIZED, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 1000)' },
  /* Message "Data Added Successfully" or the success message is configured by the user. */
  { code: ZohoStatusCodes.SUCCESS, status: HTTP_STATUS_CODES.OK, message: 'Success.' },
  /* You have reached your API call limit for a minute. Please try again after sometime. */
  { code: ZohoStatusCodes.API_CALL_LIMIT_EXCEEDED_PER_MINUTE, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 2955)' },
  /* API looping to same component exceeded the specified limit of 1. */
  { code: ZohoStatusCodes.API_LOOP_LIMIT_TO_SAME_COMPONENT_EXCEEDED, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 9030)' },
  /* API looping to other component exceeded the specified limit of 3. */
  { code: ZohoStatusCodes.API_LOOP_LIMIT_TO_OTHER_COMPONENT_EXCEEDED, status: HTTP_STATUS_CODES.NOT_FOUND, message: 'Something went wrong on our services. Please contact support for assistance. (Code: 9040)' },
];


export {
  apiErrorCodes,
  ZohoStatusCodes,
  HTTP_STATUS_CODES
}