import { useState, useEffect } from 'react';

const useBrand = (brand) => {
  const [brandSlogan, setBrandSlogan] = useState("");
  const [brandLogo, setBrandLogo] = useState("");
  const [favicon, setFavicon] = useState("");
  const [brandBrief, setBrandBrief] = useState("");
  const [redirectUrl, setRedirectUrl] = useState(null);
  
  useEffect(() => {
    const brandRedirectUri = "https://app" + brand.ShortId + ".iot-ide.com";
    setRedirectUrl(brandRedirectUri);
    setBrandSlogan(brand.whiteLabel.slogan);
    setBrandBrief(brand.whiteLabel.brief);
    setBrandLogo(`${window.location.origin}/brands/${window.location.hostname}/image?shortId=${brand.ShortId}&whiteLabelId=${brand.whiteLabel.ID}&kind=logo`);
    setFavicon(`${window.location.origin}/brands/${window.location.hostname}/image?shortId=${brand.ShortId}&whiteLabelId=${brand.whiteLabel.ID}&kind=favicon`);
  }, [brand.ShortId, brand.whiteLabel.slogan, brand.whiteLabel.brief, brand.whiteLabel.logo, brand.whiteLabel.favicon, brand.whiteLabel.ID]);

  return { brandSlogan, brandLogo, brandBrief, favicon, redirectUrl };
};

export {
  useBrand
}
