import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './avatar.css'; // Assuming you have a CSS file for styling

const Avatar = ({ email, size, brand }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const firstLetter = email.charAt(0).toUpperCase();
  const username = email.split('@')[0];

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  const logoutUser = () => {
    window.location.replace(`/brands/${brand.ShortId}/logout`);
  }

  return (
    <div className="avatar-container" onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip} onClick={togglePopover}>
      <span className="username">{username}</span>
      <div className="avatar" style={{ width: size, height: size, fontSize: size / 2 }}>
        {firstLetter}
      </div>
      {showTooltip && <div className="tooltip">{email}</div>}
      {showPopover && (
        <div className="popover">
          <p>{username}</p>
          <button onClick={logoutUser}>Sign out</button>
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  email: PropTypes.string.isRequired,
  size: PropTypes.number,
};

Avatar.defaultProps = {
  size: 50,
};

export default Avatar;
