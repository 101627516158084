function getSubdomainDomain(url) {
  // Create a URL object
  const parsedUrl = new URL(url);

  // Get the hostname from the URL
  const hostname = parsedUrl.hostname;

  // Split the hostname into parts
  const parts = hostname.split('.');

  // Check if the hostname has a subdomain
  if (parts.length > 2) {
      // Return subdomain.domain.com
      return parts.slice(-3).join('.');
  } else {
      // Return domain.com
      return hostname;
  }
}

export {
  getSubdomainDomain
}