import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { getSubdomainDomain } from './utils/http';
import ErrorPage from './components/error';

fetch(`brands/${getSubdomainDomain(window.location.href)}`)
  .then(async (brandRequest) => {
    brandRequest = JSON.parse(await brandRequest.text());
    if (brandRequest.code === 3000) {
      const [brand] = brandRequest.data;
      const brandRedirectUri = "https://app" + brand.ShortId + ".iot-ide.com";
      document.getElementById("initial-style").remove();
      document.getElementById("loading-text").remove();
      const rootDiv = document.createElement("div");
      rootDiv.id = 'root';
      document.body.appendChild(rootDiv);
  
      ReactDOM.render(
        <Auth0Provider
          domain={brand.env.AUTH0_DOMAIN}
          clientId={brand.env.AUTH0_CLIENT_ID}
          authorizationParams={{ redirect_uri: window.location.origin }}
          onRedirectCallback={(appState, user) => {
            window.location.replace(brandRedirectUri);
          }}
        >
          <App 
            brand={brand}/>
        </Auth0Provider>,
        document.getElementById('root')
      );
    } else {
      throw brandRequest;
    }
  })
  .catch(err => {
    document.title = "We're sorry, error occured";
    document.getElementById("initial-style").remove();
    document.getElementById("loading-text").remove();
    const rootDiv = document.createElement("div");
    rootDiv.id = 'root';
    document.body.appendChild(rootDiv);
    ReactDOM.render(      
        <ErrorPage 
          code={err.code}
        />,
      document.getElementById('root')
    );
  });

